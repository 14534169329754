import React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import { LayoutWhite } from "@layout"
import { HeroLegal2022Q3 } from "@components/hero"
import SEO from "@components/seo"
import "@styles/legal.scss"

const Sweepstakes = () => {
  return (
    <LayoutWhite
      backgroundImage="animated"
      hero={<HeroLegal2022Q3 title="ATM.com&trade; Sweepstakes" />}
    >
      <SEO title="Sweepstakes" />
      <Container className="policy-container body-1">
        <h2 className="underline">OFFICIAL RULES</h2>
        <p>
          <strong>
            NO PURCHASE NECESSARY TO ENTER OR WIN. <br />A PURCHASE OR PAYMENT
            WILL NOT IMPROVE YOUR CHANCE OF WINNING.
          </strong>
        </p>
        <p>
          <strong>
            Receive an entry by simply completing a survey, playing a game, or
            completing a partner offer (see Section 5 below).
          </strong>
        </p>
        <p>
          <u>1. Eligibility:</u> This ATM.com&trade; March 2024 Sweepstakes
          (“Sweepstakes”) is open only to legal residents of, and physically
          located within, the fifty (50) United States and District of Columbia
          and are of age eighteen (18) years or older at time of entry. If a
          potential winner is at least 18 years old but still considered a minor
          in his/her jurisdiction of residence, prize may be awarded in the name
          of his/her parent or legal guardian who will be responsible for
          fulfilling all requirements imposed on winner(s) set forth herein. All
          federal, state, and local laws and regulations apply. Void where
          prohibited by law. Void outside the USA, in Puerto Rico, in the US
          Virgin Islands, and in other United States territories and
          possessions. Employees of ATM.com, Inc. (the “Sponsor”) and its
          respective affiliates, subsidiaries, successors, assigns, agents,
          representatives, officers, directors, shareholders, and employees, and
          any entity involved in the development, production, implementation,
          administration, judging or fulfillment of the Sweepstakes, including
          without limitation, the immediate family members of such individuals,
          are not eligible to participate Winning the Grand Prize is contingent
          upon fulfilling all requirements set forth herein.
        </p>
        <p>
          <u>2. Sponsorship:</u> The Sweepstakes sponsor is ATM.com, Inc,
          (“Sponsor”), located at 4600 Campus Drive, Suite 107, Newport Beach,
          CA 92660. Sponsor will conduct the Sweepstakes substantially as
          described in these Official Rules.
        </p>
        <p>
          <u>3. Agreement to Rules:</u> By entering this Sweepstakes, the
          entrant (“Entrant” or “You”) agrees to abide by the Sponsor's Official
          Rules and decisions, which are fully and unconditionally binding in
          all respects. The Sponsor reserves the right to refuse, withdraw, or
          disqualify any entry at any time at the Sponsor’s sole discretion. By
          entering this Sweepstakes, You represent and warrant that You are
          eligible to participate based on eligibility requirements explained in
          the Official Rules. You also agree to accept the decisions of the
          Sponsor as final and binding as it relates to the content of this
          Sweepstakes.
        </p>
        <p>
          <u>4. Sweepstakes Entry Periods:</u> The Sponsor will award monthly
          prizes and weekly prizes. The Sweepstakes Entry Periods are as
          follows:
        </p>
        <ul>
          <li>
            The “Monthly Entry Period” begins on March 1, 2024, at 12:01am PST
            and ends on March 31, 2024, at 11:59pm PDT{" "}
          </li>
          <li>
            Weekly Entry Period #1 begins on March 4, 2024, at 12:00am PST and
            ends on March 10, 2024, at 11:59pm PDT
          </li>
          <li>
            Weekly Entry Period #2 begins on March 11, 2024, at 12:00am PDT and
            ends on March 17, 2024, at 11:59pm PDT
          </li>
          <li>
            Weekly Entry Period #3 begins on March 18, 2024, at 12:00am PDT and
            ends on March 24, 2024, at 11:59pm PDT
          </li>
          <li>
            Weekly Entry Period #4 begins on March 25, 2024, at 12:00am PDT and
            ends on March 31, 2024, at 11:59pm PDT
          </li>
        </ul>
        <p>
          To be eligible for the Sweepstakes, entries must be received within
          the specified Monthly Entry Period or one of the Weekly Entry Periods.
        </p>
        <p>
          <u>5. How to Enter:</u> Users will be automatically entered in the
          Sweepstakes for the Monthly Prizes by taking any of three actions on
          the ATM.com app during the Monthly Entry Period – actions for which
          there is no fee or payment required. You will receive one (1) entry
          for: every five (5) surveys You submit, for which You receive a
          minimum payout of three cents ($0.03), and for which the payout reward
          is not reversed, or; every five (5) game rewards that are not
          reversed, or: every one (1) partner offer that is completed, as
          defined by the Sponsor in its sole discretion, and that is not
          reversed. Eligibility for each Weekly Entry Period will be based on a
          specific Action which will be announced and visible on the App’s
          Sweepstakes screen.
        </p>
        <p>
          <u>6. Mail-In Entries:</u> You may also enter the Sweepstakes by
          sending an envelope via regular United States Postal Service first
          class mail to the Sponsor at the address shown in Section 2 above. The
          envelope should contain a 3” x5” card with your name, complete mailing
          address (no P.O. boxes), email address, and the mobile phone number
          associated with your ATM.com account. Mailed entries must be
          postmarked no later than March 31, 2024, and must be received in the
          Sponsor’s office no later than April 4, 2024. Mail-in entries will be
          eligible for weekly prizes based on the postmarked date on the
          envelope and must be received by the Sponsor by the Tuesday following
          the end of the Weekly Entry Period. Handwritten mail-in entries must
          be legible, clear, and easy to read.
        </p>
        <p>
          <u>7. Limitations on Entries:</u> There is no limit to the number of
          entries You may submit and you may win more than one prize.
        </p>
        <p>
          <u>8. Monthly Prizes:</u> One (1) Grand Prize will be awarded. The
          Grand Prize consists of $1,000.00 deposited into the winner’s ATM
          account and distributed based on their account configuration. The
          Second Prizes each consist of $500.00 deposited into the winner’s ATM
          account and distributed based on their account configuration.
        </p>
        <p>
          <u>9. Weekly Prizes:</u> In addition to the Monthly Prizes, the
          Sponsor will also select and award two prizes for each Weekly Entry
          Period. Each weekly prize consists of $50.00 deposited into the
          winner’s ATM account and distributed based on their account
          configuration.
        </p>
        <p>
          <u>10. Prize Winners:</u> The Winners, upon acceptance of their prize,
          are solely responsible for all expenses related to the prize,
          including without limitation, any and all federal, state, and local
          taxes, if applicable. The prizes are non-transferable and no prize
          substitutions are allowed. The Grand Prize winner and all other
          winners will be obligated to validate their identity and send proof of
          age and residency by providing a copy of their photo I.D. before the
          prize is awarded. The Grand Prize Winner must provide Sponsor with a
          completed and signed IRS Form W-9 which includes their Social Security
          number before the prize will be awarded. An IRS Form 1099-MISC will be
          issued to the Grand Prize winner. (See Section 13 for more details)
        </p>
        <p>
          <u>11. Odds:</u> The odds of winning will depend on the number of
          eligible entries received, according to the eligibility requirements
          in the Sweepstakes.
        </p>
        <p>
          <u>12. Random Drawing:</u> Winners will be selected in random drawings
          from among all eligible entries collected during the entry periods at
          Sponsor’s principal place of business, taking place on or about as
          follows:
        </p>
        <ul>
          <li>Weekly Entry Period #1 – March 13, 2024 at 11:00am PDT</li>
          <li>Weekly Entry Period #2 – March 20, 2024 at 11:00am PDT</li>
          <li>Weekly Entry Period #3 – March 27, 2024 at 11:00am PDT</li>
          <li>Weekly Entry Period #4 – April 17, 2024 at 11:00am PDT</li>
          <li>Monthly Entry Period – April 17, 2024, at 11:00am PDT</li>
        </ul>
        <p>
          The drawing will be conducted by Sponsor, whose decisions and
          interpretations on all matters relating to the Sweepstakes and these
          Official Rules are final and binding in all respects.
        </p>
        <p>
          <u>13. Selection and Notification of Winner:</u> Each potential winner
          will be notified by email or phone using the contact information
          associated with their ATM.com account. Sponsor shall have no liability
          for a winner's failure to receive notices due to winner's spam, junk
          e-mail or other security settings or for winners' provision of
          incorrect or otherwise non-functioning contact information. Potential
          winners must reply with all requested information within seventy-two
          (72) hours of the first attempted winner notification in order to
          claim their prize. Winners will be required to complete, sign and
          return an Affidavit of Eligibility/Liability Release, and, where
          lawful, Publicity Release and other documents as requested by Sponsor
          prior to acceptance of the prize. If, despite reasonable efforts, any
          prize notification is returned as unclaimed or undeliverable, any
          potential winner is found to be ineligible, has not complied with
          these Official Rules, cannot be verified, fails to return any required
          documents, or declines a prize for any reason, such potential winner
          will be disqualified and at Sponsor’s discretion, Sponsor may award
          the prize to an alternate potential winner selected from the remaining
          eligible entries. POTENTIAL WINNERS ARE SUBJECT TO VERIFICATION BY
          SPONSORS; THE DECISIONS OF THE SPONSOR ARE FINAL AND BINDING. AN
          ENTRANT IS NOT A WINNER OF ANY PRIZE, UNLESS AND UNTIL THE WINNER HAS
          BEEN VERIFIED AND NOTIFIED THAT THE VERIFICATION IS COMPLETE.
        </p>
        <p>
          <u>14. Publicity:</u> Except where prohibited by law, by accepting the
          prize, the selected Monthly Prize Winners grant permission for the
          Sponsor to use their likeness, entry, and name for purposes of
          advertising and trade without further compensation unless prohibited
          by law.
        </p>
        <p>
          <u>15. Limitation of Liability:</u> By entering You agree to release
          and hold harmless ATM.com, Inc. and its subsidiaries, affiliates,
          advertising and promotion agencies, partners, representatives, agents,
          successors, assigns, employees, officers and directors from any
          liability, illness, injury, death, loss, litigation, claim or damage
          that may occur, directly or indirectly, whether caused by negligence
          or not, from (i) such entrant's participation in the sweepstakes
          and/or his/her acceptance, possession, use, or misuse of any prize or
          any portion thereof, (ii) technical failures of any kind, including
          but not limited to the malfunctioning of any computer, cable, network,
          hardware or software; (iii) the unavailability or inaccessibility of
          any transmissions or telephone or Internet service; (iv) unauthorized
          human intervention in any part of the entry process or the
          Sweepstakes; (v) electronic or human error which may occur in the
          administration of the Sweepstakes or the processing of entries.
        </p>
        <p>
          <u>16. Disputes:</u> By participating, entrants agree that: (a) any
          and all disputes, claims and causes of action arising out of, or
          connected with, this Sweepstakes or the Prize, shall be resolved
          individually, without resort to any form of class action, and
          exclusively by the appropriate federal, state or local court located
          in Orange County, California; (b) any and all claims, judgments and
          awards shall be limited to actual out-of-pocket costs incurred,
          including but not limited to costs associated with entering this
          Sweepstakes, but in no event attorneys’ fees; and (c) to the extent
          allowed by applicable law, under no circumstances will Entrant be
          permitted to obtain awards for, and entrant hereby waives all rights
          to claim, punitive, incidental and/or consequential damages and/or any
          other damages, other than actual out-of-pocket expenses, and any and
          all rights to have damages multiplied or otherwise increased. SOME
          JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF LIABILITY
          FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO PORTIONS OF THE ABOVE MAY
          NOT APPLY TO YOU.
        </p>
        <p>
          <u>17. Privacy Policy:</u> Information submitted with an entry is
          subject to the Privacy Policy stated on the ATM.com website and in the
          ATM.com app. <Link to="/privacy-policy">Click here</Link> to read the
          Privacy Policy.
        </p>
        <p>
          <u>18. Winners List:</u> You may obtain a copy of the winners’ names,
          where permitted by law, or a copy of these Official Rules, by sending
          your request, separate from mail-in entries, via mail with a stamped,
          self-addressed envelope to: ATM.com, Inc., 4600 Campus Drive, Suite
          107, Newport Beach, CA 92660. Requests must be postmarked no later
          than April 30, 2024.
        </p>
        <p>
          <u>19. Governing Law:</u> All disputes concerning the construction,
          validity, interpretation and enforceability of these Official Rules or
          the rights and obligations of entrants or Sponsor in connection with
          the Sweepstakes shall be governed by and interpreted in accordance
          with the laws of the State of California, without regard to its
          conflict of laws principles. The parties hereby consent to exclusive
          jurisdiction and venue of the state and federal courts located in
          Orange County, California, in any action relating to these Official
          Rules or the Sweepstakes.
        </p>
        <p>
          <u>20. General Terms and Conditions:</u> By entering this Sweepstakes,
          You accept and agree to be bound by the Ofﬁcial Rules and decisions of
          Sponsor, which will be ﬁnal and binding in all respects. Sponsor
          reserves the right, at its sole discretion, to refuse, disqualify or
          withdraw any entry at any time. Sponsor will not be responsible for
          any injury, damage or loss of any kind arising out of your
          participation in the Sweepstakes. In no event will Sponsor be liable
          for awarding more than one (1) Grand Prize. By entering the
          Sweepstakes, each entrant gives his/her express permission to be
          contacted by Sponsor by telephone, email and/or postal mail for
          Sweepstakes purposes. The Sponsor is not responsible for any
          typographical or other error in the printing of this offer,
          administration of this Sweepstakes or in the announcement of winner
          and prize. In the event the Sponsor is prevented from continuing with
          this promotion, or the integrity and/or feasibility of the promotion
          as planned is undermined by any event including but not limited to
          fire, flood, epidemic, pandemic, earthquake, explosion, labor dispute
          or strike, act of God or public enemy, satellite or equipment failure,
          riot or civil disturbance, war (declared or undeclared), terrorist
          threat or activity, or any federal, state, or local government law,
          order, or regulation, order of any court or jurisdiction (each a
          “Force Majeure” event or occurrence), Sponsor shall have the right, in
          its sole discretion, to abbreviate, modify, suspend, cancel, or
          terminate the sweepstakes and award the prize from among all eligible
          entries received up to that date. All federal, state and local laws
          and regulations apply. All trademarks used herein are the property of
          their respective owners. The invalidity or unenforceability of any
          provision of these Official Rules shall not affect the validity or
          enforceability of any other provision. In the event that any provision
          is determined to be invalid or otherwise unenforceable or illegal,
          these Official Rules shall otherwise remain in effect and shall be
          construed in accordance with their terms as if the invalid or illegal
          provision were not contained herein. In the event there is a
          discrepancy or inconsistency between disclosures or other statements
          contained in any Contest-related materials or made by any
          representative of Sponsors, and the terms and conditions of these
          Official Rules, these Official Rules shall prevail, govern and
          control.
        </p>
      </Container>
    </LayoutWhite>
  )
}

export default Sweepstakes
